<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-3 shadow"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              v-if="!userIsRI"
              @click="setStatus(1)"
              :active="status == 1"
            >
              Active
            </b-nav-item>
            <b-nav-item @click="setStatus(2)" :active="status == 2">
              Shipped
            </b-nav-item>
            <b-nav-item @click="setStatus(3)" :active="status == 3">
              Landed
            </b-nav-item>
            <b-nav-item
              v-if="!userIsRI"
              @click="setStatus(0)"
              :active="status == 0"
            >
              Canceled
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import { generalMixin } from "../../mixin";

export default {
  mixins: [generalMixin],
  data() {
    return {
      status: this.userIsRI ? 2 : 1,
    };
  },
  methods: {
    setStatus(status) {
      this.status = status;
      this.$emit("set-status", status);
    },
  },
};
</script>

<style></style>
