export const defaultFields = [
  {
    key: "marketPlace",
    label: "MKT",
    sortable: true,
    module: ''
  },
  {
    key: "stockNumber",
    label: "Stock Number",
    class: "text-center",
    module: "stock"
  },
  {
    key: "vinNumber",
    label: "Vin Number",
    class: "text-center",
    module: ""
  },
  {
    key: "buyFrom",
    label: "Buy from",
    class: "text-center text-break",
    module: "buyFrom"
  },
  {
    key: "year",
    label: "Year",
    class: "text-center",
    module: ""
  },
  {
    key: "model",
    label: "Model",
    class: "text-center",
    module: ""
  },
  {
    key: "user",
    label: "Agent",
    module: "agents"
  },
];

export const optionalFields = [
  {
    key: "make",
    label: "Make",
    class: "text-center",
    module: "make"
  },
  {
    key: "releaseDate",
    label: "Release Date",
    class: "text-center",
    module: "releaseDate"
  },

  {
    key: "payments",
    label: "Payments",
    class: "text-center",
    module: "payments"
  },
  {
    key: "addons",
    label: "Addons",
    class: "text-center",
    module: "addons"
  },
  /*   {
      key: "express",
      label: "Express",
      class: "text-center",
    }, */
  {
    key: "expenses",
    label: "PNL",
    class: "text-center",
    module: "addons"
  },
  {
    key: "registration",
    label: "Reg",
    class: "text-center",
    module: "registration"
  },
  {
    key: "creditRequest",
    label: "FR",
    class: "text-center",
    module: "creditRequest" //"antes era sold"
  },
  {
    key: "carfax",
    label: "Carfax",
    class: "text-center",
    module: "carfax"
  },
  {
    key: "lien",
    label: "Liens",
    class: "text-center",
    module: "liens"
  },
  {
    key: "recall",
    label: "Recall",
    class: "text-center",
    module: "recall"
  },

  {
    key: "transfer",
    label: "Transfer",
    class: "text-center",
    module: "transfer"
  },
  {
    key: "visualInspection",
    label: "V. I.",
    class: "text-center",
    module: "vi"
  },
  {
    key: "cluster",
    label: "Cluster",
    class: "text-center",
    module: "cluster"
  },
  {
    key: "carwash",
    label: "Carwash",
    class: "text-center",
    module: "carwash"
  },

  {
    key: "workOrders",
    label: "W. O.",
    class: "text-center",
    module: "wo"
  },
  {
    key: "conditionalReports",
    label: "CR",
    class: "text-center",
    module: "cr"
  },
  {
    key: "offers",
    label: "Offers",
    class: "text-center",
    module: "offers"
  },
  {
    key: "sold",
    label: "Sold",
    class: "text-center",
    module: "sold"
  },
  {
    key: "visualInspectionCR",
    label: "V. I. C.R.",
    class: "text-center",
    module: "vi"
  },

  {
    key: "prices",
    label: "Prices",
    class: "text-center",
    module: "prices"
  },

  /*  {
    key: "export",
    label: "Export",
    class: "text-center",
  }, */

  {
    key: "consignment",
    label: "SL",
    class: "text-center",
    module: "sl"

  },
  /* {
    key: "jacket",
    label: "Jacket",
    class: "text-center",
  }, */
  {
    key: "files",
    label: "Jacket",
    class: "text-center",
    module: "jacket"

  },
  {
    key: "exception",
    label: "",
    class: "text-center",
    module: "exception"
  },
  {
    key: "reserve",
    label: "Reserve",
    class: "text-center",
    module: "reserve"
  },
  {
    key: "soldInfo",
    label: "Sold to",
    class: "text-center",
    module: ""

  },

  {
    key: "quickTransfer",
    label: "Transfer",
    class: "text-center",
    module: "quickTransfer"
  },
  {
    key: "location",
    label: "Location",
    module: ""
  },
  {
    key: "notes",
    label: "Notes",
    class: "text-center",
    module: "notes"
  },
  {
    key: "policeBook",
    label: "P.B.",
    class: "text-center",
    module: "registration"
  },
  {
    key: "soldTo",
    label: "Sold to",
    class: "text-center",
    module: "soldToRi"
  },
  {
    key: "reserveTo",
    label: "Reserve to",
    class: "text-center",
    module: "reserveToRi"
  }





  /* {
    key: "releaseDate",
    label: "Release Date",
    class: "text-center",
    module: "releaseDate"
  },
  {
    key: "make",
    label: "Make",
    class: "text-center",
    module: "make"
  } */
];
