<template>
  <div>
    <Nav @set-status="setStatus" />
    <div class="container-fluid">
      <div class="mb-3 ml-1 d-flex justify-content-end">
        <b-dropdown
          text="Download excel"
          class="mr-0"
          variant="success"
          :disable="isDownloadingExcelByType"
        >
          <b-dropdown-item @click="downloadExcelByType(1)"
            >Without 7501</b-dropdown-item
          >
          <b-dropdown-item @click="downloadExcelByType(2)"
            >Without title before</b-dropdown-item
          >
          <b-dropdown-item @click="downloadExcelByType(3)"
            >Without title after</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div class="">
        <b-table
          show-empty
          bordered
          small
          stacked="md"
          head-variant="light"
          :items="exportLoads.data.data"
          :fields="fields"
          :busy="exportLoads.status === 'fetching'"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" variant="yellow" />
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="text-center">
              <b-icon
                v-if="row.field.details && !userIsRI"
                v-b-tooltip.hover
                title="Details"
                icon="eye"
                class="h5 m-2 cursor-pointer"
                @click="editItem(row.item)"
              />

              <b-icon
                icon="box-arrow-right"
                v-if="
                  row.item.vehicles.length > 0 &&
                  row.item.status == 1 &&
                  userHasPermission('exportLoads', 'write') &&
                  !userIsRI
                "
                v-b-tooltip.hover
                title="Mark as shipped"
                class="h5 m-2 cursor-pointer"
                @click="openDateModal(row.item)"
              ></b-icon>
              <b-icon
                icon="x-circle"
                v-if="
                  row.item.status == 1 &&
                  userHasPermission('exportLoads', 'write') &&
                  !userIsRI
                "
                v-b-tooltip.hover
                title="Cancel"
                class="h5 m-2 cursor-pointer"
                @click="updateStatus(row.item.id, 0)"
              ></b-icon>
              <b-icon
                icon="check-square"
                v-if="
                  row.item.status == 2 &&
                  userHasPermission('exportLoads', 'write')
                "
                v-b-tooltip.hover
                title="Mark as closed"
                class="h5 m-2 cursor-pointer"
                @click="openDateModal(row.item)"
              ></b-icon>
              <b-icon
                v-if="
                  row.item.vehicles.length && !isDownloadingExcel && !userIsRI
                "
                icon="file-earmark-excel"
                class="cursor-pointer h5 m-2"
                @click="getExcel(row.item)"
                v-b-tooltip.hover
                title="Download Excel"
              ></b-icon>
              <b-spinner
                v-else-if="!userIsRI"
                variant="yellow"
                small
                class="mb-2"
              />

              <font-awesome-icon
                v-if="!isDownloadingPdf && !userIsRI"
                class="cursor-pointer m-2"
                :icon="['far', 'file-pdf']"
                size="lg"
                @click="getPdf(row.item)"
              />
              <b-spinner
                v-if="isDownloadingPdf && !userIsRI"
                variant="yellow"
                small
                class="mb-2"
              />

              <b-icon
                v-if="!userIsRI"
                @click="openEmailBodyModal(row.item)"
                icon="envelope"
                class="cursor-pointer h5 m-2"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="exportLoads.status != 'fetching'"
          :value="exportLoads.data.currentPage"
          :total-rows="exportLoads.data.total"
          :per-page="exportLoads.data.perPage"
          class="mb-2"
          align="end"
          @change="changePage($event)"
        />
      </div>

      <b-modal
        id="detailsModal"
        ref="detailsModal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
        scrollable
        @hide="hideModal"
      >
        <ExportLoadRegistrationForm
          :exportLoadItem="exportLoadItem"
          :refreshData="getData"
          :hideModal="hideModal"
        />
      </b-modal>
      <b-modal
        id="date-modal"
        ref="date-modal"
        size="lg"
        hide-footer
        body-class="pl-0 pr-0"
        ok-disabled
      >
        <LoadDate :load="load" :refreshData="getData" />
      </b-modal>
      <EmailBody @submit="sendByEmail" :id="1">
        <template slot="top">
          <b-form-group label-size="sm">
            <template slot="label">
              To: <span class="text-danger"> </span>
            </template>
            <b-form-input
              id="email-input"
              v-model="emailTo"
              size="sm"
              name="email-input"
              :formatter="lowerCaseFormatter"
              maxlength="255"
            />
          </b-form-group>
        </template>
      </EmailBody>
    </div>
  </div>
</template>
<script>
import ExportLoadRegistrationForm from "./ExportLoadRegistrationForm.vue";
import Nav from "./Nav.vue";
import {
  GET_EXPORT_LOADS,
  GET_VEHICLES_LOAD,
  GET_EXPORT_LOCATIONS_LIST,
  PUT_LOAD_STATUS,
  GET_EXPORT_LOAD_EXCEL,
  GET_EXPORT_LOAD_PDF,
  GET_LOAD_PDF,
  GET_FILES_BY_EMAIL,
  GET_EXPORT_LOAD_EXCEL_BY_TYPE,
} from "./actions";
import LoadDate from "./LoadDate.vue";
import { mapState } from "vuex";

import { generalMixin } from "@/modules/mixin.js";
import { superAdminMixin } from "../mixins";
import EmailBody from "@/modules/shared/EmailBody";

export default {
  name: "ExportLoadTable",
  components: { ExportLoadRegistrationForm, Nav, LoadDate, EmailBody },
  mixins: [generalMixin, superAdminMixin],
  computed: {
    ...mapState({
      exportLoads: (state) => state.superAdminStore.exportLoad.exportLoads,
      exportLoadPDF: (state) => state.superAdminStore.exportLoad.loadPdf,
    }),
    fields() {
      return this.getFieldsToShow();
    },
    hasPermission() {
      return this.userHasPermission("exportLoads", "read");
    },
  },
  data() {
    return {
      vehiclesSelected: [],
      tableFields: [
        { key: "location.name", label: "Export load" },
        { key: "referenceNumber", label: "Reference number" },
        {
          key: "createdAt",
          label: "Created at",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "shippingDate",
          label: "Shipped date",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "finalDate",
          label: "Landed date",
          class: "text-center",
          formatter: (date) => this.$options.filters.formatDate(date),
        },
        {
          key: "actions",
          label: "Actions",
          note: false,
          details: true,
          validation: false,
          delete: true,
        },
      ],
      options: {
        assign: false,
        rolFilter: false,
        add: false,
        fun: this.getData,
      },
      status: 1,
      exportLoadItem: {},
      load: {},
      filter: null,
      loadToEmail: {},
      emailTo: "",
      isDownloadingPdf: false,
      isDownloadingExcel: false,
      isDownloadingExcelByType: false,
    };
  },
  mounted() {
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("exportLoads", "write") ? this.showModal : null
    );
    this.getData();
    this.getVehicles();
    this.getExportLocations();
    this.updateTable();
  },
  methods: {
    getData(filter = "") {
      if (this.userIsRI && this.status == 1) {
        this.status = 2;
      }
      this.$store.dispatch(GET_EXPORT_LOADS, {
        status: this.status,
        search: filter,
      });
    },
    updateStatus(id, status) {
      this.$store
        .dispatch(PUT_LOAD_STATUS, { id: id, status: status })
        .then(() => {
          this.$root.$bvToast.toast("Export load updated successfully", {
            title: "Success",
            variant: "success",
          });
          this.getData();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Export load could not be updated", {
            title: "Warning",
            variant: "warning",
          });
        });
    },

    showModal() {
      this.exportLoadItem = {};
      this.$bvModal.show("detailsModal");
    },
    editItem(item) {
      this.exportLoadItem = item;
      this.$bvModal.show("detailsModal");
    },

    hideModal() {
      this.$bvModal.hide("detailsModal");
    },
    getVehicles() {
      this.$store.dispatch(GET_VEHICLES_LOAD);
    },
    getExportLocations() {
      this.$store.dispatch(GET_EXPORT_LOCATIONS_LIST);
    },
    setStatus(status) {
      console.log(status);
      this.status = status;
      this.getData();
      this.updateTable();
    },
    changePage(page, filter = "") {
      const payload = {
        search: filter,
        page: page,
        status: this.status,
      };
      this.$store
        .dispatch(GET_EXPORT_LOADS, payload)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    getExcel(exportLoad) {
      this.isDownloadingExcel = true;

      this.$store
        .dispatch(GET_EXPORT_LOAD_EXCEL, { id: exportLoad.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "export-load" + ".xls" || "ficher.xls";
          link.click();
        })
        .finally(() => {
          this.isDownloadingExcel = false;
        });
    },
    getPDF(exportLoad) {
      this.$store
        .dispatch(GET_EXPORT_LOAD_PDF, { id: exportLoad.id })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "export-load" + ".pdf" || "ficher.pdf";
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "Can't download the file, please try again later",
            {
              title: "Error",
              variant: "warning",
            }
          );
        });
    },
    updateTable() {
      /*   if (this.status != 2 && this.status != 3) {
        this.fields.splice(2, 1);
        this.fields.splice(2, 1);
      } */
    },
    openDateModal(load) {
      this.load = load;
      this.$bvModal.show("date-modal");
    },
    getFieldsToShow() {
      const fields = [...this.tableFields];
      if (this.status == 1) {
        fields.splice(2, 1);
        fields.splice(2, 1);
      }
      /* if (this.status == 2) {
        fields.splice(3, 1);
      } */
      if (this.status == 0) {
        fields.splice(3, 1);
      }

      return fields;
    },
    getPdf(load) {
      this.isDownloadingPdf = true;
      this.$store
        .dispatch(GET_LOAD_PDF, { id: load.id })
        .then((response) => {
          if (response.type == "application/json") {
            setTimeout(() => {
              this.getPdf(load);
            }, 2000);
          } else {
            const blob = new Blob([response], {
              type: "application/pdf",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = this.getPDFName(load) || "file.pdf";
            link.click();
            this.isDownloadingPdf = false;
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("Could not get file", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    sendByEmail(body) {
      this.$store
        .dispatch(GET_FILES_BY_EMAIL, {
          body: body,
          email: this.emailTo,
          id: this.loadToEmail.id,
        })
        .then(() => {
          this.$root.$bvToast.toast("Email sent successfully", {
            title: "Success",
            variant: "success",
          });
          this.$root.$bvModal.hide("email-body-modal" + 1);
        })
        .catch(() => {
          this.$root.$bvToast.toast("Email could not be sent", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    openEmailBodyModal(load) {
      this.loadToEmail = load;
      this.$root.$bvModal.show("email-body-modal" + 1);
    },
    getPDFName(load) {
      const date = new Date();

      const fileName =
        "exportLoad-" +
        date.getFullYear() +
        "-" +
        date.getMonth() +
        "-" +
        date.getDay() +
        "-" +
        load.id +
        ".pdf";
      return fileName;
    },
    downloadExcelByType(type) {
      this.isDownloadingExcelByType = true;
      this.$store
        .dispatch(GET_EXPORT_LOAD_EXCEL_BY_TYPE, { type: type })
        .then((response) => {
          const blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "export-load" + ".xls" || "ficher.xls";
          link.click();
        })
        .catch(() => {
          this.$root.$bvToast.toast(
            "Can't download the file, please try again later",
            {
              title: "Error",
              variant: "warning",
            }
          );
        })
        .finally(() => {
          this.isDownloadingExcelByType = false;
        });
    },
  },
};
</script>
<style scoped>
.filter-input {
  background-color: #1d3030;
  opacity: 1;
  color: white;
  border: none;
}
.form-control:focus {
  background-color: #1d3030;
  color: white;
  border-color: #1d3030;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
</style>
